<template>
  <div class="container-fluid full-width-page-wrapper">
    <div class="row">
      <div class="col my-auto">
        <h3 class="page-title">My Applications</h3>
      </div>
    </div>
    <tabs
      :tabItems="TAB_ITEMS"
      defaultTab="active">
      <template slot="tab-content">
        <component
          :is="activeTabComponent"/>
        <router-view />
      </template>
    </tabs>
  </div>
</template>

<script>
import { ConferenceSectionTabs } from '@/services/tabs';
import Tabs from '@/views/components/Tabs.vue';
import Active from '@/views/areas/conferences/index-partials/Active.vue';
import Historic from '@/views/areas/conferences/index-partials/Historic.vue';

export default {
  name: 'Conferences',
  components: {
    Tabs,
    Active,
    Historic,
  },
  created() {
    this.TAB_ITEMS = ConferenceSectionTabs;
  },
  computed: {
    activeTabComponent() {
      return this.$route.query.section ?? this.TAB_ITEMS[0]?.name;
    },
  },
};
</script>
