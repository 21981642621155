<template>
  <ag-grid-vue
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :rowData="rowData"
    :gridOptions="gridOptions"
    @gridReady="onGridReady"
    @cellClicked="onCellClicked"
    rowSelection="single"
    domLayout="autoHeight"
  ></ag-grid-vue>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { ConferenceStatuses } from '@ifac/ui';
import Conferences from '@/services/Api/Conferences';
import GridMixin from '@/views/components/grids/GridMixin';
import {
  gridColumns,
  gridDefaultColDef,
} from '@/views/components/grids/conferences/ConferencesGrid';

export default {
  name: 'Conferences',
  components: {
    AgGridVue,
  },
  mixins: [GridMixin],
  data() {
    return {
      rowData: [],
      defaultColDef: gridDefaultColDef,
      columnDefs: gridColumns,
    };
  },
  methods: {
    postGridReady() {
      this.refresh();
    },
    async refresh() {
      this.refreshing = true;

      try {
        const { data } = await Conferences.all();
        const { completed, cancelled, archived } = ConferenceStatuses;
        this.rowData = data.data.items?.filter(
          (c) => [completed.id, cancelled.id, archived.id].includes(c?.status),
        );

        this.busy = false;
      } finally {
        this.refreshing = false;
      }
    },
    onCellClicked({ data }) {
      this.$router.push({
        name: 'ConferencesCompleted',
        params: {
          id: data.id,
        },
      });
    },
  },
};
</script>
