<template>
  <div class="container card conference-card border-0 shadow bg-light">
    <div class="card-body" :class="[conference ? 'has-summary' : '']">
      <ifac-loader
        v-if="busy"
        :style="{height: `${conferenceHeight}px`}"
      />
      <div class="row" v-else>
        <div class="col-12" v-if="activeConference">
          <ifac-conference-summary
            is-owner
            with-pdf
            with-header
            with-submission
            :countries="countries"
            :read-only="isReadOnly"
            :conference="activeConference"
            :conference-types="conferenceTypes"
            :technical-committees="technicalCommittees"
            :uncompleted-sections="uncompletedSections(activeConference)"
          ></ifac-conference-summary>
        </div>
        <div class="col text-center no-active-applications" v-else>
          <h2>No active applications</h2>
          <p>
            IFAC Affiliates are more than welcome to apply for IFAC sponsorship or co-sponsorship for conferences,
            workshops, symposia, colloquia etc. To do so, first read in details the Organiser's guide
            (<a href="https://www.ifac-control.org/conferences/organizer-guide" target="_blank"
            >https://www.ifac-control.org/conferences/organizer-guide</a>). Then you may submit an application.
          </p>
          <router-link to="/conferences/apply" class="btn btn-primary">
            Start new application
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { IfacLoader, IfacConferenceSummary, ConferenceStatuses } from '@ifac/ui';
import Conferences from '@/services/Api/Conferences';

export default {
  components: {
    IfacLoader,
    IfacConferenceSummary,
  },
  created() {
    this.fetchConferences();
  },
  data() {
    return {
      busy: true,
      conference: null,
      activeConference: null,
      conferences: null,
    };
  },
  computed: {
    ...mapGetters({
      countries: 'countries/data',
      conferenceTypes: 'conferenceTypes/data',
      uncompletedSections: 'conferences/uncompletedSections',
      technicalCommittees: 'coordinatingCommittees/technicalCommitteeList',
    }),
    isReadOnly() {
      const { unsubmitted, approved } = ConferenceStatuses;
      return this.activeConference.status !== unsubmitted.id && this.activeConference.status !== approved.id;
    },
    conferenceHeight() {
      return 260;
    },
  },
  methods: {
    async fetchConferences() {
      this.busy = true;
      const { data } = await Conferences.all();
      this.conferences = data.data;
      const { completed, cancelled, archived } = ConferenceStatuses;
      this.activeConference = this.conferences?.items?.find(
        (c) => ![completed.id, cancelled.id, archived.id].includes(c?.status),
      );
      if (this.activeConference) {
        await this.fetchConference();
      }
      this.busy = false;
    },
    async fetchConference() {
      const { data } = await Conferences.get(this.activeConference.id);
      this.activeConference = data.data;
    },
  },
};
</script>
